import React, { useState, useEffect } from "react"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"

// async function loadJsonLdTag() {
// }

const NotFoundPage = () => {
  const [jsonLdTag, setJsonLdTag] = useState('')

  useEffect(() => {
    console.log('useEffect');
    setTimeout(() => {
      console.log('setTimeout');
      setJsonLdTag(`<script type="application/ld+json">
      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Executive Anvil",
        "image": [
          "https://example.com/photos/1x1/photo.jpg",
          "https://example.com/photos/4x3/photo.jpg",
          "https://example.com/photos/16x9/photo.jpg"
         ],
        "description": "Sleeker than ACME's Classic Anvil, the Executive Anvil is perfect for the business traveler looking for something to drop from a height.",
        "sku": "0446310786",
        "mpn": "925872",
        "brand": {
          "@type": "Brand",
          "name": "ACME"
        },
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "4",
            "bestRating": "5"
          },
          "author": {
            "@type": "Person",
            "name": "Fred Benson"
          }
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.4",
          "reviewCount": "89"
        },
        "offers": {
          "@type": "Offer",
          "url": "https://example.com/anvil",
          "priceCurrency": "USD",
          "price": "119.99",
          "priceValidUntil": "2020-11-20",
          "itemCondition": "https://schema.org/UsedCondition",
          "availability": "https://schema.org/InStock"
        }
      }
  </script>`)

      // const script = document.createElement('script');

      // script.src = "https://use.typekit.net/foobar.js";
      // script.async = true;
    
      // document.body.appendChild(script);
    }, 5000)
  }, [])

  const jsonLdTagNote = jsonLdTag ? 'Tag added' : 'No tag'
  console.log('Render');

  return (
    <Layout>
      <Seo title="JSON+LD Test" />
      <h1>JSON+LD Test</h1>
      <p>{jsonLdTagNote}</p>
      <div dangerouslySetInnerHTML={ { __html: jsonLdTag } }></div>
    </Layout>
  )
}

export default NotFoundPage
